import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/news_subscription_form.json"
import DefaultForm from "../forms/default-form-layout"

const StayUpdatedModule = () => {
  const [modalShow, setModalShow] = useState(false)

  const closeModal = () => {
    setModalShow(false)
    localStorage.setItem("showpopup", false)
  }
  useEffect(() => {
    const showPopup =
      localStorage.getItem("showpopup") === null
        ? true
        : localStorage.getItem("showpopup") === "false"
        ? false
        : false

    if (showPopup) {
      setTimeout(() => {
        setModalShow(true)
      }, 1000)
    }
  }, [])
  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal stay-updated contact-form"
        id="filter-modal"
        backdrop="static"
        // fullscreen={"xl-down"}
      >
        <button
          className="stay-button-form cancel-button"
          type="button"
          onClick={() => closeModal()}
        >
          <i className="icon black-cancel" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
          <h2>Connect with us</h2>
                        <div className="contact-text">
                        Get bi-monthly updates on all the latest insight, events and market updates straight to your inbox.
                        </div>
          <DefaultForm
                  fields={FormFields}
                  formtagclassname="subscription-form"
                  formName="subscription"
                />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default StayUpdatedModule
