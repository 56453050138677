import React from "react";
import { Link, graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import { PageLinks } from "../common/site/page-static-links";
import Seo from "../components/seo"
import StayUpdatedModule from "../components/StayUpdatedModule/StayUpdatedModule";
import { homeValuation, instantValuation } from "../site/urls";
import NewsStructuredData from "../components/Seo/NewsStructuredData";
import { format } from "date-fns/esm"


const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const NewsDetailDesc = loadable(() => import("../components/NewsDetailDesc/NewsDetailDesc"));
const MoreNewsModule = loadable(() => import("../components/MoreNews/MoreNews"));

const NewsDetail = ({ data }, props) => {
    const PageData = data?.strapiBlog
    const MoreNews = data?.allStrapiBlog.edges
    const sidebarData = data?.strapiGlobalMenu
    const metaTitle=PageData?.title;
    const metaDescription=`Read about ${metaTitle?.toLowerCase()} here and subscribe to our newsletter to stay up-to-date about everything going on at tlc.`

    const formattedDate = format(new Date(PageData.date), "d MMMM y")

    const pageurl = typeof window !== "undefined" ? window.location.href : ""
    return (
        <Layout popularSearch="static-details">
            <NewsStructuredData
              image={PageData?.banner_image?.url}
              pageurl={pageurl}
              title={metaTitle}
              // author={blogDetails?.author?.name}
              description={metaDescription}
              date={formattedDate}
            />
            
            <div className="layout-padding-top">
              <div className="bottom-right-image-home">
                <div className="hover-block">
                  <div className="block-elements">
                  {/* <Link to={instantValuation}>Instant Online Valuation</Link> */}
                  {/* <div className="vertical-line"></div> */}
                  <Link to={homeValuation}>Home Visit Valuation</Link>
                  </div>
                </div>
                <div className="banner-img"></div>
              </div>
                <BreadcrumbModule mainparentname={`About`} mainparent={PageLinks.about} parentname={`News & Insights`} parent={PageLinks.news} pagename={PageData?.title} />
                <NewsDetailDesc sidebarData={sidebarData}  {...PageData} />
                <MoreNewsModule data={MoreNews}/>
<StayUpdatedModule />
            </div>
        </Layout>
    )
}



export const Head = ({ data }) => {
  const PageData = data?.strapiBlog
  const metaTitle=PageData?.title;
  const metaDescription=`Read about ${metaTitle?.toLowerCase()} here and subscribe to our newsletter to stay up-to-date about everything going on at tlc.`
  return (
      <Seo title={metaTitle} description={metaDescription} image={PageData.banner_image ? PageData.banner_image.url : ''} />
  )
}

export default NewsDetail



export const query = graphql`
query ($page_id: Int) {
    strapiBlog(strapi_id: {eq: $page_id}) {
        ...BlogFragment
        banner_image {
          url
        }
        imagetransforms{
            banner_image_Transforms
            module_image_Transforms
        }
        blog_content {
            ... on STRAPI__COMPONENT_COMPONENTS_CONTENT_BLOCK {
                strapi_component
                content {
                    data {
                      content
                    }
                  }
            }
            ... on STRAPI__COMPONENT_COMPONENTS_IMAGE_BLOCK {
                strapi_component         
                image {
                    url
                }
            }
        }
    }
    
    allStrapiBlog(filter: {strapi_id: {ne: $page_id}}, limit: 4) {
        edges {
            node {
                ...BlogFragment
                tile_image {
                    url
                }
            }
        }
    }

    strapiGlobalMenu {
        strapi_id
        imagetransforms {
            sidebar_image_Transforms
        }
        news_sidebar {
            title
            content {
              data {
                content
              }
            }
            image {
              url
            }
            add_link {
              label
              link {
                slug
                strapi_parent {
                  slug
                  strapi_parent {
                    slug
                  }
                }
              }
            }
          }
    }
  }
`