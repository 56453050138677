import React from "react"
import { Helmet } from "react-helmet"

function NewsStructuredData({ description, pageurl, title, image, date }) {
  var ldJson = {
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": pageurl,
    },
    headline: title,
    image: [image],
    publisher: {
      "@type": "Organization",
      name: "Olivers Town Estate Agents",
      logo: {
        "@type": "ImageObject",
        url: "https://ggfx-oliverstown.s3.eu-west-2.amazonaws.com/i.prod/logo_a1747dfb98.png",
      },
    },
    description: description,
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}

export default NewsStructuredData
